  
.search {
  border: 2px solid white;
  border-radius: 5px;
  outline: none;
  font-size: 15px;
   transition: 1s;
  font-size: 20px;
 background-color: white;
}
 

.search-form  {
  height: 40px;
  width: 400px;
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}
.search-btn {
  color: white;
  background: black;
  font-size: 20px;
  border-radius: 10px;
  border: 2px solid white;
}
 
 
.logo a{
    /* /* margin-bottom: 10px; */
  font-family:sans-serif;
  animation-duration: 3s;
  animation-delay: 0.5s;
}
.logo img {
  /* border-radius: 50%; */
  max-width: 20%;
  /* -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite; */
}
 