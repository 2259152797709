    ::-webkit-scrollbar {
        width: 20px;
      }
      ::-webkit-scrollbar-track {
        background-color: transparent;
      }
      ::-webkit-scrollbar-thumb {
        background-color: #d6dee1;
        border-radius: 20px;
        border: 6px solid transparent;
        background-clip: content-box;
      }
      ::-webkit-scrollbar-thumb:hover {
        background-color: #a8bbbf;
      }
      #chat-home{
        position: relative;
        min-height: fit-content;
      }
      .btn {
        padding: 14px 14px;
        border-radius: 6px;
        font-weight: bold;
        font-size: 1.1rem;
        cursor: pointer;
        border: none;
      }
      .btn-outline {
        color: rgb(153, 217, 234);
        border: 1px solid rgb(153, 217, 234);
       }
      .btn-primary {
        background: rgb(153, 217, 234);
        color: rgb(0, 24, 111);
      }
      .btn-secondary {
        background: rgb(0, 24, 111);
        color: black;
      }
    
      .container {
        height: 80vh;
         justify-content: center;
        align-items: center;
       }
      .formContainer {
        width: 400px;
        margin: 0 auto 0 auto;
        padding: 32px;
        background: lightblue;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 28px;
      }
      .input {
        width: 100%;
        padding: 12px;
        border-radius: 6px;
         font-size: 0.9rem;
      }
      .input option {
        margin-top: 20px;
      }
