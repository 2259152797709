.card-image{
    width: 100%;
    position: relative;
    margin-bottom: 10px;
}
section{
    height: fit-content;
    display: block;
}
.card{
    width: 100%;
    position: relative;
    height: auto;
    padding: auto;
    padding-bottom: 10px;
    margin: auto;
    margin-bottom: 10px;
}
.card-image img {
    width: 100%;
}
.card-price{
    position: absolute;
    top: 0;
    right: 0%;
    background: rgb(256, 256, 256, 0.7);
    border-radius: 50%;
    border: 2px dotted red;
    color: red;
    padding: 0px 10px;
    padding-top: 10px;
    font-weight: 800;
    margin: auto;
    justify-self: center;
    justify-content: center;
    vertical-align: middle;
}
.card-title{
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0%;
    z-index: 2;
    right: 0%;
    text-align: center;
    background: rgb(000, 000, 000, 0.7);
    color: white;
    padding: 5px 10px;
    font-weight: 800;
    margin: auto;
    justify-self: center;
    justify-content: center;
    vertical-align: middle;
}
a{
    transform-style: inherit;
    font-style: normal;
    text-decoration: none;
}
.badge{
    font-size: 14px;
}
table.table.table-responsive, tr, td, th, table, div, body, html {
    max-width: 100%; 
    overflow-x: hidden;
    overflow-y: auto;
}