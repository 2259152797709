.switch {
    position: fixed;
    display: inline-block;
    right: 1%;
    bottom: 5%;
    width: 60px;
    height: 34px;
    z-index: 99;
}
.language {
    position: fixed;
    display: inline-block;
    right: 1%;
    bottom: 9%;
    width: 60px;
    height: 34px;
    z-index: 99;

}

/* Hide default HTML checkbox */
.switch input, .language input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "L";
    height: 26px;
    font-size: 18px;
    font-weight: 800;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    color: #000000;
    padding: 0%;
    text-align: center;
    -webkit-transition: .4s;
    transition: .4s;
}
input:checked + .slider {
    background-color: #000000;
}

input:focus + .slider {
    box-shadow: 1px 2px 1px #000000;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    content: "D";

}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
/* The language slider */
.language .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.language .slider:before {
    position: absolute;
    content: " AR";
    font-weight: 800;
    font-size: small;
    padding: 2px;
    height: 26px;
    color: black;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
.language input:checked + .slider {
    background-color: #000000;
}

.language input:checked + .slider:before {
     content: "EN";
}

.language input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.language .slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

