 
/* CSS */
.button-85 {
  /* padding: 8px 8px; */
  border: none;
  outline: none;
  color: white;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 1;
  border-radius: 20px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  display: inline-block;
  margin: 20px auto;
}

.button-85:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 2px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.card-image-full{
  position: relative;
  height: 30%;
  max-width: 100%;
}
.card-image-full img{
  height:100%;
  max-width: 500px;
}
 