.message-component{
    display: flex;
    justify-content:center;
    align-items: center;

}


.Message-form{
    height: 100%;
    width: 100%;
     display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
 
}

.Message-form input{
    font-size: 20px;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    border: 1px  #00AA80 solid;
    outline: none;
    font-size: 20px;
    width: 60%;
}

.message-input{
    height: 100px;
    width: 250px;
    font-size: 20px;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    border: 1px  #00AA80 solid;
    outline: none;

}
.send{
    margin-bottom: 10px;
}

#messages-tabContent{
    height: 80vh;
    overflow-y: auto;
}

#messagesReply{
    max-height: 50%;    
    overflow-y: scroll;
  overscroll-behavior-y: contain;
  scroll-snap-type: y proximity;
}
#messageReply .message:last-child {
    scroll-snap-align: end;
}

#replymsg{
     overflow-y: auto;
}

