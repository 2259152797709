 
  
  /* Room and users component */
  .roomAndUsersColumn {
    border-right: 1px solid gray;
  }
  .roomTitle {
    margin-bottom: 60px;
    text-transform: uppercase;
    font-size: 2rem;
    color: black;
  }
  .usersTitle {
    font-size: 1.2rem;
    color: black;
  }
  .usersList {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 60px;
    color: rgb(153, 217, 234);
  }
  .usersList li {
    margin-bottom: 12px;
  }
  
  /* Messages */
  .messagesColumn {
    height: 85vh;
    overflow: auto;
    padding: 10px 10px 10px 40px;
  }
  #sendmessage{
    padding: 10px;
    margin: auto;
  }
 
  .message {
    border-radius: 6px;
    border: 1px solid black;
    background-color: rgba(123, 188, 192, 0.5);
    margin-bottom: 24px;
    max-width: 600px;
    padding: 12px;
  }
  .mymessage {
    border: 1px dotted green;
    background-color: rgba(82, 106, 93, 0.5);
    border-radius: 6px;
    margin-bottom: 24px;
    max-width: 600px;
    padding: 12px;
  }
  .msgMeta {
    color: black;
    font-size: 0.75rem;
  }
  .msgText {
    color: black;
  }
  
 